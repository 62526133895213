@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

/* common css */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
li {
  list-style-type: none;
}
a:hover {
  text-decoration: none;
}
button {
  cursor: pointer;
}
input:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}
select:focus {
  outline: none;
}

.container_fluid {
  width: 100%;
  float: left;
  min-height: 100vh;
  background: transparent linear-gradient(180deg, #90194180 0%, #f04f2380 100%)
    0% 0% no-repeat padding-box;
}

.container {
  max-width: 1280px;
  clear: both;
  padding: 10px;
  margin: 0 auto;
}

img {
  max-width: 100%;
  display: block;
}

input,
textarea,
select,
button {
  font-family: "Quicksand", sans-serif;
}
/* a{color: #1897f2;} */

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #737584;
  opacity: 1;
}
input::-moz-placeholder,
textarea::-webkit-input-placeholder {
  color: #737584;
  opacity: 1;
}
input:-ms-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #737584;
  opacity: 1;
}
input:-moz-placeholder,
textarea::-webkit-input-placeholder {
  color: #737584;
  opacity: 1;
}

/* header css */
.header_container_fluid {
  background-color: #901941;
  height: 100px;
  border-radius: 0px 0px 50px 50px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.header_content {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.header_content h1 {
  font-size: 40px;
  font-family: "Quicksand", sans-serif;
  color: #ffffff;
}

/* media css */

/* 
@media (max-width: 500px) {


} */
@media (min-width: 501px) and (max-width: 575px) {
  .modal_user{
    width: 100%;
    max-width: 400px !important;
    padding: 20px;
  
  }
  .header_content h1{
    font-size: 20px;
  }
}
@media (max-width: 500px) {
  /* .header_images{
    display: none;
  } */
  .header_content h1{
    font-size: 20px;
  }
.modal_user{
  width: 100%;
  max-width: 350px !important;
  padding: 20px;

}
  
}
@media (max-width: 500px) {
  .header_content h1 {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .Home_content {
    flex-direction: column;
    padding: 0px 20px;
  }

  /* .visit_container{
  display: flex;
  flex-direction: column;
  align-items: center;
} */
  .visit_container_sub {
    width: 100%;
  }
}

@media (min-width: 768.1px) and (max-width: 1120px) {
  .cover_conatiner{
    width: 80% !important;
  }
}
@media (min-width: 375px) and (max-width: 575px) {
  .cover_conatiner{
    width: 80% !important;
  }
}
@media (min-width: 768.1px) and (max-width: 1120px) {



  .Home_content {
    flex-direction: column;
    padding: 0px 20px;
  }


  /* .visit_container{
    display: flex;
    flex-direction: column;
    align-items: center;
  } */
  .visit_container_sub {
    width: 100%;
  }
}

/* sidebar csssss */
.sidebar_container {
  display: flex;
  margin: 0px auto;
  background: #ffffff;
  /*   width: 1350px; */
}

.sidebar_contant {
  /* transition: all 0.2s; */
  background: #ffffff;

}

.cover_conatiner {
  width: 100%;
  margin-left: 97px;

}
.top_section {
  display: flex;
  align-items: center;
  padding: 30px 0px;
  justify-content: center;
}
.input_text_error_span {
  display: none;
}
.link {
  /* display: flex; */
  color: #901941;
  padding: 10px 15px;
  text-decoration: none;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  gap: 25px;
  margin-bottom: 14px;
  font-family: "Quicksand", sans-serif;
  align-items: center;

}



.signup_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.signup_content h1 {
  color: #ffffff;
  /* color: #901941; */
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-size: 50px;
  font-weight: 600;
  margin: 20px 0px;
}
.signup_container_left_typo {
  color: #ffffff;
  /* color: #901941; */
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-size: 20px;
  font-weight: 600;
}
.input_label {
  color: #ffffff;
  /* color: #901941; */
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-size: 20px;
  font-weight: 600;
}
.input_text_error {
  color: #ff0000;
  font-family: "Quicksand", sans-serif;
  font-size: 18px;
  font-weight: 600;
}
.user-details-strong{
  color: #901941;
  font-family: "Quicksand", sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.login_password {
  margin-top: 20px;
}
.error {
  color: red;
  padding-left: 5px;
}
.inputStyle {
  padding: 15px 0px;
  font-weight: 500;
  font-size: 18px;
  font-family: "Quicksand", sans-serif;
  border: none;
  border-bottom: 3px solid #ffffff;
  background: none;
}

.inputError {
  padding: 15px 0px;
  font-weight: 500;
  font-size: 18px;
  font-family: "Quicksand", sans-serif;

  border: none;
  border-bottom: 3px solid #ff0000;
  background: none;
  margin-bottom: 20px;
}
.inputStyleToggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid #ffffff;
  box-sizing: border-box;
  margin-bottom: 10px;
  color: #979797;
  padding: 15px 0px;
  margin-bottom: 20px;
}
.inputErrorToggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid #ff0000;
  box-sizing: border-box;
  margin-bottom: 10px;
  color: #979797;
  padding: 15px 0px;
  margin-bottom: 20px;
}

.signin_button{
  padding: 15px;
  font-weight: 600;
  font-size: 18px;
  font-family: "Quicksand", sans-serif;
  background: #901941;
  color: #ffffff;
  border: 2px solid #901941;
  border-radius: 20px;

}
.signin_button:hover{
color: #901941;
background: #ffffff;

}
.login_gap {
  display: flex;
  flex-direction: column;
}
.inputpass {
  border: none;
  outline: none;
  background: none;
  flex: 1;
  font-weight: 500;
  font-size: 18px;
  font-family: "Quicksand", sans-serif;
}
.forgot_password {
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
}
.forgot_password a {
  text-decoration: none;
  color: #ffffff;
  font-weight: 500;
  font-size: 20px;
  font-family: "Quicksand", sans-serif;
}
.logo_login{
  display: flex;
  justify-content: center;
  margin: 10px 0px;
}
.logo_login img{
width: 177px;
height: 65px;
}
.edit_heading{
  font-weight: 600;
  font-size: 25px;
  font-family: "Quicksand", sans-serif;
  color: #901941;
  margin-bottom: 20px;
  text-align: center;

}

.edit_gap{
  margin-top: 20px;
}
.modal_user{

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  max-width: 800px;
}
